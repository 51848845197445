.containerSpinner {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 30px;
   
}

.spinnerActual {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border:5px solid #ccc;
    border-radius: 50%;
    
}

.overlay {
    z-index: 0;
    animation:rt 2s infinite;
    border-top:5px solid #33478e;
    background-color: transparent;

}

